var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('datatable', {
    ref: "table",
    attrs: {
      "fields": _vm.fields,
      "table-props": _vm.tableProps,
      "total": _vm.total,
      "filter": _vm.searchFilter,
      "options": _vm.tableOptions,
      "per-page-options": _vm.perPageOptions
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "topbar",
      fn: function fn() {
        return [_c('div', {
          staticClass: "card-header pt-0 px-0 align-items-center"
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "lg": ""
          }
        }, [_c('h5', {
          staticClass: "mb-0 d-flex align-items-center"
        }, [_c('div', {
          staticClass: "mr-4"
        }, [_c('country-flag', {
          attrs: {
            "country-iso": _vm.nationalContract.country
          }
        })], 1), _c('div', [_vm._v(" " + _vm._s(_vm.nationalContractName) + " ")])])]), _c('b-col', {
          attrs: {
            "cols": "12",
            "lg": "4"
          }
        }, [_c('div', {
          staticClass: "d-flex"
        }, [_c('euro-input', {
          staticClass: "w-100 mb-2 mb-lg-0",
          attrs: {
            "placeholder": _vm.$t('Search'),
            "debounce": 300
          },
          model: {
            value: _vm.searchFilter,
            callback: function callback($$v) {
              _vm.searchFilter = $$v;
            },
            expression: "searchFilter"
          }
        }, [_c('b-input-group-prepend', [_c('span', {
          staticClass: "input-group-text border-0 d-flex align-items-center justify-content-center py-0 pl-3 pr-1 m-0"
        }, [_c('i', {
          staticClass: "flaticon-search"
        })])])], 1)], 1)]), _c('b-col', {
          attrs: {
            "lg": "2"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "success",
            "block": ""
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.openCreateDialog.apply(null, arguments);
            }
          }
        }, [_c('i', {
          staticClass: "fas fa-plus"
        }), _vm._v(" " + _vm._s(_vm.$t("Level")) + " ")])], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "cell.base_pay",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "h-100"
        }, [_vm._v(" " + _vm._s(item.base_pay) + " "), _c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(item.base_pay_currency))])])];
      }
    }, {
      key: "cell.edr",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "h-100"
        }, [_vm._v(" " + _vm._s(item.edr) + " "), _c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(item.edr_currency))])])];
      }
    }, {
      key: "cell.seniority_pay_increase",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "h-100"
        }, [_vm._v(" " + _vm._s(item.seniority_pay_increase) + " "), _c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(item.seniority_pay_increase_currency))])])];
      }
    }, {
      key: "cell.tfr_ccnl",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "h-100"
        }, [_vm._v(" " + _vm._s(item.tfr_ccnl) + " "), _c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(item.tfr_ccnl_currency))])])];
      }
    }, {
      key: "cell.actions",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm mr-2",
          attrs: {
            "title": _vm.$t('Edit')
          },
          on: {
            "click": function click($event) {
              return _vm.openEditDialog(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Design/Edit.svg"
          }
        })], 1)]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm",
          attrs: {
            "title": _vm.$t('Delete')
          },
          on: {
            "click": function click($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/General/Trash.svg"
          }
        })], 1)])], 1)];
      }
    }], null, true)
  }), _c('validation-observer', {
    ref: "validationObserver"
  }, [_c('b-modal', {
    ref: "upsertModal",
    attrs: {
      "size": "xl",
      "hide-footer": ""
    },
    on: {
      "hidden": _vm.onUpsertModalHidden
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1"
        }, [_c('h3', {
          staticClass: "mb-0"
        }, [_vm._v(_vm._s(_vm.$t("National Contract Level Form")))]), _c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-2",
          class: {
            'spinner spinner-light spinner-right': _vm.isSaving
          },
          attrs: {
            "variant": "success"
          },
          on: {
            "click": _vm.submit
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]), _c('b-button', {
          on: {
            "click": _vm.closeUpsertModal
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")])], 1)])];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Level'),
      "rules": "required|min_value:1"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('euro-input', {
          attrs: {
            "label": _vm.$t('Level'),
            "error-messages": errors,
            "type": "number"
          },
          model: {
            value: _vm.form.level,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "level", $$v);
            },
            expression: "form.level"
          }
        })];
      }
    }])
  })], 1), _c('b-col', [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Level Name'),
      "vid": "level_name",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('euro-input', {
          attrs: {
            "label": _vm.$t('Level Name'),
            "error-messages": errors
          },
          model: {
            value: _vm.form.level_name,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "level_name", $$v);
            },
            expression: "form.level_name"
          }
        })];
      }
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Level Description'),
      "vid": "level_description"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('euro-input', {
          attrs: {
            "label": _vm.$t('Level Description'),
            "error-messages": errors
          },
          model: {
            value: _vm.form.level_description,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "level_description", $$v);
            },
            expression: "form.level_description"
          }
        })];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Base Pay'),
      "vid": "base_pay",
      "rules": "required|min_value:0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('euro-input', {
          attrs: {
            "label": "".concat(_vm.$t('Base Pay'), "*"),
            "error-messages": errors,
            "type": "number"
          },
          model: {
            value: _vm.form.base_pay,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "base_pay", $$v);
            },
            expression: "form.base_pay"
          }
        })];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "".concat(_vm.$t('Seniority Pay Increase'), "*"),
      "vid": "seniority_pay_increase",
      "rules": "required|min_value:0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var errors = _ref10.errors;
        return [_c('euro-input', {
          attrs: {
            "label": "".concat(_vm.$t('Seniority Pay Increase'), "*"),
            "error-messages": errors,
            "type": "number"
          },
          model: {
            value: _vm.form.seniority_pay_increase,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "seniority_pay_increase", $$v);
            },
            expression: "form.seniority_pay_increase"
          }
        })];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "".concat(_vm.$t('Days Before Seniority Increase'), "*"),
      "vid": "days_before_seniority_increase",
      "rules": "required|min_value:0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var errors = _ref11.errors;
        return [_c('euro-input', {
          attrs: {
            "label": "".concat(_vm.$t('Days Before Seniority Increase'), "*"),
            "error-messages": errors,
            "type": "number"
          },
          model: {
            value: _vm.form.days_before_seniority_increase,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "days_before_seniority_increase", $$v);
            },
            expression: "form.days_before_seniority_increase"
          }
        })];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t(' Max Seniority Increase Number'),
      "vid": "max_seniority_increase_number",
      "rules": "required|min_value:0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var errors = _ref12.errors;
        return [_c('euro-input', {
          attrs: {
            "label": "".concat(_vm.$t('Max Seniority Increase Number'), "*"),
            "error-messages": errors,
            "type": "number"
          },
          model: {
            value: _vm.form.max_seniority_increase_number,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "max_seniority_increase_number", $$v);
            },
            expression: "form.max_seniority_increase_number"
          }
        })];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('EDR'),
      "vid": "edr",
      "rules": "required|min_value:0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var errors = _ref13.errors;
        return [_c('euro-input', {
          attrs: {
            "label": "".concat(_vm.$t('EDR'), "*"),
            "error-messages": errors,
            "type": "number"
          },
          model: {
            value: _vm.form.edr,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "edr", $$v);
            },
            expression: "form.edr"
          }
        })];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('TFR Divider'),
      "vid": "tfr_divider",
      "rules": "required|min_value:0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var errors = _ref14.errors;
        return [_c('euro-input', {
          attrs: {
            "label": "".concat(_vm.$t('TFR Divider'), "*"),
            "error-messages": errors,
            "type": "number"
          },
          model: {
            value: _vm.form.tfr_divider,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "tfr_divider", $$v);
            },
            expression: "form.tfr_divider"
          }
        })];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('TFR CCNL'),
      "vid": "tfr_ccnl",
      "rules": "required|min_value:0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var errors = _ref15.errors;
        return [_c('euro-input', {
          attrs: {
            "label": "".concat(_vm.$t('TFR CCNL'), "*"),
            "error-messages": errors,
            "type": "number"
          },
          model: {
            value: _vm.form.tfr_ccnl,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "tfr_ccnl", $$v);
            },
            expression: "form.tfr_ccnl"
          }
        })];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Divider'),
      "vid": "divider",
      "rules": "required|min_value:0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref16) {
        var errors = _ref16.errors;
        return [_c('euro-input', {
          attrs: {
            "label": _vm.$t('Divider'),
            "error-messages": errors,
            "type": "number"
          },
          model: {
            value: _vm.form.divider,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "divider", $$v);
            },
            expression: "form.divider"
          }
        })];
      }
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Is 13th Month Paid'),
      "vid": "is_13th_month_paid"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref17) {
        var errors = _ref17.errors;
        return [_c('euro-checkbox', {
          attrs: {
            "horizontal": "",
            "label": _vm.$t('Is 13th Month Paid'),
            "error-messages": errors
          },
          model: {
            value: _vm.form.is_13th_month_paid,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "is_13th_month_paid", $$v);
            },
            expression: "form.is_13th_month_paid"
          }
        })];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Is 14th Month Paid'),
      "vid": "is_14th_month_paid"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref18) {
        var errors = _ref18.errors;
        return [_c('euro-checkbox', {
          attrs: {
            "horizontal": "",
            "label": _vm.$t('Is 14th Month Paid'),
            "error-messages": errors
          },
          model: {
            value: _vm.form.is_14th_month_paid,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "is_14th_month_paid", $$v);
            },
            expression: "form.is_14th_month_paid"
          }
        })];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Is 15th Month Paid'),
      "vid": "is_15th_month_paid"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref19) {
        var errors = _ref19.errors;
        return [_c('euro-checkbox', {
          attrs: {
            "horizontal": "",
            "label": _vm.$t('Is 15th Month Paid'),
            "error-messages": errors
          },
          model: {
            value: _vm.form.is_15th_month_paid,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "is_15th_month_paid", $$v);
            },
            expression: "form.is_15th_month_paid"
          }
        })];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }