<template>
  <div>
    <datatable ref="table" :fields="fields" :table-props="tableProps" :total="total" :filter="searchFilter"
      :options.sync="tableOptions" :per-page-options="perPageOptions">
      <template #topbar>
        <div class="card-header pt-0 px-0 align-items-center">
          <b-row>
            <b-col cols="12" lg="">
              <h5 class="mb-0 d-flex align-items-center">
                <div class="mr-4">
                  <country-flag :country-iso="nationalContract.country"></country-flag>
                </div>
                <div>
                  {{ nationalContractName }}
                </div>
              </h5>
            </b-col>
            <b-col cols="12" lg="4">
              <div class="d-flex">
                <euro-input v-model="searchFilter" :placeholder="$t('Search')" class="w-100 mb-2 mb-lg-0" :debounce="300">
                  <b-input-group-prepend>
                    <span
                      class="input-group-text border-0 d-flex align-items-center justify-content-center py-0 pl-3 pr-1 m-0"><i
                        class="flaticon-search"></i></span>
                  </b-input-group-prepend>
                </euro-input>
              </div>
            </b-col>
            <b-col lg="2">
              <b-button variant="success" block @click.prevent="openCreateDialog">
                <i class="fas fa-plus"></i>
                {{ $t("Level") }}
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>

      <template #[`cell.base_pay`]="{ item }">
        <div class="h-100">
          {{ item.base_pay }} <span class="font-weight-bolder">{{ item.base_pay_currency }}</span>
        </div>
      </template>

      <template #[`cell.edr`]="{ item }">
        <div class="h-100">
          {{ item.edr }} <span class="font-weight-bolder">{{ item.edr_currency }}</span>
        </div>
      </template>

      <template #[`cell.seniority_pay_increase`]="{ item }">
        <div class="h-100">
          {{ item.seniority_pay_increase }}
          <span class="font-weight-bolder">{{ item.seniority_pay_increase_currency }}</span>
        </div>
      </template>

      <template #[`cell.tfr_ccnl`]="{ item }">
        <div class="h-100">
          {{ item.tfr_ccnl }} <span class="font-weight-bolder">{{ item.tfr_ccnl_currency }}</span>
        </div>
      </template>

      <template #[`cell.actions`]="{ item }">
        <div class="d-flex">
          <b-button v-b-tooltip.hover :title="$t('Edit')" class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
            @click="openEditDialog(item)">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/media/svg/icons/Design/Edit.svg" />
            </span>
          </b-button>
          <b-button v-b-tooltip.hover :title="$t('Delete')" class="btn btn-icon btn-light btn-hover-primary btn-sm"
            @click="remove(item)">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/media/svg/icons/General/Trash.svg" />
            </span>
          </b-button>
        </div>
      </template>
    </datatable>

    <validation-observer ref="validationObserver">
      <b-modal ref="upsertModal" size="xl" hide-footer @hidden="onUpsertModalHidden">
        <template #modal-header>
          <div class="w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1">
            <h3 class="mb-0">{{ $t("National Contract Level Form") }}</h3>
            <div class="d-flex align-items-center">
              <b-button variant="success" class="mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
                @click="submit">
                {{ $t("Submit") }}
              </b-button>
              <b-button @click="closeUpsertModal">
                {{ $t("Cancel") }}
              </b-button>
            </div>
          </div>
        </template>

        <b-container fluid>
          <b-row>
            <b-col>
              <validation-provider v-slot="{ errors }" :name="$t('Level')" rules="required|min_value:1">
                <euro-input v-model="form.level" :label="$t('Level')" :error-messages="errors" type="number"></euro-input>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider v-slot="{ errors }" :name="$t('Level Name')" vid="level_name" rules="required">
                <euro-input v-model="form.level_name" :label="$t('Level Name')" :error-messages="errors"></euro-input>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <validation-provider v-slot="{ errors }" :name="$t('Level Description')" vid="level_description">
                <euro-input v-model="form.level_description" :label="$t('Level Description')"
                  :error-messages="errors"></euro-input>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" :name="$t('Base Pay')" vid="base_pay" rules="required|min_value:0">
                <euro-input v-model="form.base_pay" :label="`${$t('Base Pay')}*`" :error-messages="errors"
                  type="number"></euro-input>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" :name="`${$t('Seniority Pay Increase')}*`"
                vid="seniority_pay_increase" rules="required|min_value:0">
                <euro-input v-model="form.seniority_pay_increase" :label="`${$t('Seniority Pay Increase')}*`"
                  :error-messages="errors" type="number"></euro-input>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" :name="`${$t('Days Before Seniority Increase')}*`"
                vid="days_before_seniority_increase" rules="required|min_value:0">
                <euro-input v-model="form.days_before_seniority_increase"
                  :label="`${$t('Days Before Seniority Increase')}*`" :error-messages="errors" type="number"></euro-input>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" :name="$t(' Max Seniority Increase Number')"
                vid="max_seniority_increase_number" rules="required|min_value:0">
                <euro-input v-model="form.max_seniority_increase_number"
                  :label="`${$t('Max Seniority Increase Number')}*`" :error-messages="errors" type="number"></euro-input>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" :name="$t('EDR')" vid="edr" rules="required|min_value:0">
                <euro-input v-model="form.edr" :label="`${$t('EDR')}*`" :error-messages="errors"
                  type="number"></euro-input>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" :name="$t('TFR Divider')" vid="tfr_divider"
                rules="required|min_value:0">
                <euro-input v-model="form.tfr_divider" :label="`${$t('TFR Divider')}*`" :error-messages="errors"
                  type="number"></euro-input>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" :name="$t('TFR CCNL')" vid="tfr_ccnl" rules="required|min_value:0">
                <euro-input v-model="form.tfr_ccnl" :label="`${$t('TFR CCNL')}*`" :error-messages="errors"
                  type="number"></euro-input>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" :name="$t('Divider')" vid="divider" rules="required|min_value:0">
                <euro-input v-model="form.divider" :label="$t('Divider')" :error-messages="errors"
                  type="number"></euro-input>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" lg="4">
              <validation-provider v-slot="{ errors }" :name="$t('Is 13th Month Paid')" vid="is_13th_month_paid">
                <euro-checkbox v-model="form.is_13th_month_paid" horizontal :label="$t('Is 13th Month Paid')"
                  :error-messages="errors"></euro-checkbox>
              </validation-provider>
            </b-col>

            <b-col cols="12" lg="4">
              <validation-provider v-slot="{ errors }" :name="$t('Is 14th Month Paid')" vid="is_14th_month_paid">
                <euro-checkbox v-model="form.is_14th_month_paid" horizontal :label="$t('Is 14th Month Paid')"
                  :error-messages="errors"></euro-checkbox>
              </validation-provider>
            </b-col>

            <b-col cols="12" lg="4">
              <validation-provider v-slot="{ errors }" :name="$t('Is 15th Month Paid')" vid="is_15th_month_paid">
                <euro-checkbox v-model="form.is_15th_month_paid" horizontal :label="$t('Is 15th Month Paid')"
                  :error-messages="errors"></euro-checkbox>
              </validation-provider>
            </b-col>
          </b-row>
        </b-container>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import Swal from "sweetalert2";

import Datatable from "@/view/components/tables/Datatable.vue";
import CountryFlag from "@/view/components/CountryFlag.vue";

import EmployeeNationalContractLevelService from "@/core/services/employee/employee-nationalcontract-level.service";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"
import icons from "@/core/config/icons";
import { mapState } from "vuex";
import {backendErrorSwal} from "@/core/helpers/swal";
const DEFAULT_FORM = {
  level: 1,
  level_name: "",
  level_description: "",
  base_pay: "",
  seniority_pay_increase: "",
  days_before_seniority_increase: "",
  max_seniority_increase_number: "",
  edr: "",
  tfr_divider: "",
  tfr_ccnl: "",
  divider: 0,
  is_13th_month_paid: false,
  is_14th_month_paid: false,
  is_15th_month_paid: false,
};
export default {
  components: {
    Datatable,
    CountryFlag,
  },

  props: {
    nationalContract: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      icons,
      fields: [
        { label: this.$t("Level"), key: "level", sortable: true, class: "align-middle" },
        { label: this.$t("Level Name"), key: "level_name", sortable: true, class: "align-middle" },
        { label: this.$t("Base Pay"), key: "base_pay", sortable: true, class: "align-middle" },
        { label: this.$t("EDR"), key: "edr", sortable: true, class: "align-middle" },
        {
          label: this.$t("Seniority Increase"),
          key: "max_seniority_increase_number",
          sortable: true,
          class: "align-middle",
        },
        {
          label: this.$t("seniority pay"),
          key: "seniority_pay_increase",

          sortable: true,
          class: "align-middle",
        },
        {
          key: "days_before_seniority_increase",
          label: this.$t("Days Before Seniority"),
          sortable: true,
          class: "align-middle",
        },
        { label: this.$t("TFR CCNL"), key: "tfr_ccnl", sortable: true, class: "align-middle" },
        { label: this.$t("Actions"), key: "actions", class: "align-end col-actions" },
      ],
      isSaving: false,
      form: { ...DEFAULT_FORM },
      formApiErrors: null,
      total: 0,
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
      searchFilter: "",
    };
  },
  computed: {
    ...mapState("constants", ["countries", "languages"]),
    tableProps() {
      return {
        items: this.itemProvider,
        fields: this.fields,
        "tbody-tr-class": this.trClass,
      };
    },
    nationalContractId() {
      return this.nationalContract?.id;
    },

    nationalContractCountry() {
      return this.nationalContract?.country;
    },

    nationalContractName() {
      return this.nationalContract?.name;
    },

    languageSelectable() {
      return this.languages.map(x => ({ text: x.name_en, value: x.iso_639_1 }));
    },

    countrySelectable() {
      return this.countries.map(x => ({ text: x.name, value: x.iso }));
    },

    requestBody() {
      return { ...this.form, nationalcontract: this.nationalContractId };
    },
  },
  watch: {
    formApiErrors: {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;
        const newErrors = {
          level: [...(val?.level ?? [])],
          level_name: [...(val?.level_name ?? [])],
          level_description: [...(val?.level_description ?? [])],
          base_pay: [...(val?.base_pay ?? [])],
          seniority_pay_increase: [...(val?.seniority_pay_increase ?? [])],
          days_before_seniority_increase: [...(val?.days_before_seniority_increase ?? [])],
          max_seniority_increase_number: [...(val?.max_seniority_increase_number ?? [])],
          edr: [...(val?.edr ?? [])],
          tfr_divider: [...(val?.tfr_divider ?? [])],
          tfr_ccnl: [...(val?.tfr_ccnl ?? [])],
          divider: [...(val?.divider ?? [])],
          is_13th_month_paid: [...(val?.is_13th_month_paid ?? [])],
          is_14th_month_paid: [...(val?.is_14th_month_paid ?? [])],
          is_15th_month_paid: [...(val?.is_15th_month_paid ?? [])],
        };
        formObs.setErrors({ ...newErrors });
      },
    },
    nationalContract() {
      this.$refs.table.refresh();
    }
  },
  methods: {
    itemProvider(ctx, callback) {
      EmployeeNationalContractLevelService.getByNationalContractPaginated(this.nationalContractId, {
        country: this.nationalContractCountry,
        perPage: ctx.perPage,
        page: ctx.currentPage,
        sortBy: ctx.sortBy,
        search: ctx.filter,
        sortDesc: ctx.sortDesc,
      })
        .then(res => {
          this.total = res.count;
          callback(res.results);
        })
        .catch(() => callback([]));
      return null;
    },

    extendedCountryName(iso2) {
      const x = this.countries.find(el => el.iso == iso2);
      if (!x) return iso2;
      return x.name;
    },

    extendedLanguageName(iso2) {
      const x = this.languages.find(el => el.iso_639_1 == iso2);
      if (!x) return iso2;
      return x.name_en;
    },

    openCreateDialog() {
      const upsertModal = this.$refs.upsertModal;
      upsertModal.show();
    },

    openEditDialog(item) {
      this.form = { ...item };
      const upsertModal = this.$refs.upsertModal;
      upsertModal.show();
    },

    remove(item) {
      Swal.fire({
        title: this.$t("Delete"),
        text: `${this.$t("Are you sure you want to delete this?")} ${item.level_name}`,
        icon: "warning",
        confirmButtonClass: "btn btn-danger",
        confirmButtonText: this.$t("Yes delete"),
        showCancelButton: true,
        cancelButtonText: this.$t("No, do NOT delete"),
        cancelButtonClass: "btn",
      }).then(async res => {
        if (res.isConfirmed) {
          try {
            await EmployeeNationalContractLevelService.delete(item.id);
            this.$refs.table.refresh()
            Swal.fire({
              title: this.$t("Success"),
              icon: "success",
              text: this.$t("National Contract deleted!"),
            });
          } catch (err) {
            backendErrorSwal(err, err?.response?.data?.detail);
          }
        }
      });
    },

    closeUpsertModal() {
      const upsertModal = this.$refs.upsertModal;
      upsertModal.hide();
    },

    onUpsertModalHidden() {
      this.form = { ...DEFAULT_FORM };
    },

    submit() {
      if (this.form?.id) {
        this.updateNationalContract(this.requestBody, this.form.id);
        return;
      }
      this.createNationalContract(this.requestBody);
    },

    async createNationalContract(item) {
      this.isSaving = true;
      try {
        await EmployeeNationalContractLevelService.create(item);
        this.$refs.table.refresh()
        this.closeUpsertModal();
        Swal.fire({
          title: this.$t("Success"),
          icon: "success",
          text: this.$t("National Contract created!"),
        });
      } catch (err) {
        if (err?.response?.status === 400) {
          this.formApiErrors = err.response.data;
        } else {
          backendErrorSwal(err, err?.response?.data?.detail);
        }
      } finally {
        this.isSaving = false;
      }
    },

    async updateNationalContract(item, id) {
      this.isSaving = true;
      try {
        await EmployeeNationalContractLevelService.update(item, id);
        this.$refs.table.refresh()
        this.closeUpsertModal();
        Swal.fire({
          title: this.$t("Success"),
          icon: "success",
          text: this.$t("National Contract updated!"),
        });
      } catch (err) {
        if (err?.response?.status === 400) {
          this.formApiErrors = err.response.data;
        } else {
          backendErrorSwal(err, err?.response?.data?.detail);
        }
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style scoped>
.btn.btn-outline-light {
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}

.btn.btn-outline-light i::before {
  color: #fff;
}

.btn.btn-outline-light:hover span,
.btn.btn-outline-light:hover i::before {
  color: var(--primary);
}
</style>
